<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/ford-logo.png"
            alt="logo"
          >
        </b-link>

        <b-card-title class="mb-1">
          Przypomnienie hasła
        </b-card-title>
        <b-card-text class="mb-2">
          Podaj swój adres email, aby otrzymać link do zresetowania hasła
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="submit"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="loading"
            >
              Przypomnij hasło
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link to="/login">
            <feather-icon icon="ChevronLeftIcon" /> Powrót do logowania
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      loading: false,
      // validation
      required,
      email,
    }
  },
  methods: {
    submit() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('auth/sendPasswordResetRequest', {
            email: this.userEmail,
          }).then(response => {
            if (response.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Przypominienie hasła',
                  text: `Wiadomość wysłana pomyślnie na ${this.userEmail}`,
                  icon: 'MailIcon',
                  variant: 'success',
                },
              })
              this.$router.replace('/login')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Przypominienie hasła',
                  text: `Nie udało się wysłać wiadomości na ${this.userEmail}. Być może konto z tym adresem e-mail nie istnieje.`,
                  icon: 'UserXIcon',
                  variant: 'danger',
                },
                timeout: 10000,
              })
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
